.content__pre-title {
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  text-align: center;
}

.content__text {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: $color-gray;

  @include vp-768 {
    max-width: 93%;
  }
}

.content__extra-text {
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  text-align: center;
  color: $color-gray;
}

.content__extra-title {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  margin-top: 32px;
  margin-bottom: 25px;

  @include vp-768 {
    max-width: 290px;
    margin-top: 12px;
    margin-bottom: 15px;
  }
}

.content {
  h3 {
    font-size: 24px;
    font-weight: 500;
    line-height: 29px;
    text-align: left;

    @include vp-768 {
      font-size: 32px;
      font-weight: 500;
      line-height: 38px;
      margin-top: 0;
    }
  }
}

.content__text-block {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  color: $color-gray;
}

.content {
  margin-top: 23px;
  margin-bottom: 64px;

  @include vp-768 {
    margin-top: 98px;
    margin-bottom: 68px;
  }
}

.content__wrapper-content,
.content__wrapper-pictures {
  margin-top: 45px;

  @include vp-768 {
    margin-top: 65px;
  }

  img {
    width: 100%;
    max-height: 216px;
    object-fit: cover;
    object-position: 50% 30%;
  }
}

.content__wrapper-pictures {
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-bottom: 64px;

  @include vp-768 {
    flex-direction: row;
    max-width: 818px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 44px;

    img {
      max-height: unset;
      height: 298px;
      margin-bottom: 36px;
    }
  }
}

.grid-reverse {
  @include vp-768 {
    margin-top: 90px;
    margin-bottom: 90px;

    div {
      order: -1;
    }

    .transparent-button {
      width: 229px;
      margin-left: 0;
      margin-top: 50px;
    }

    h3 {
      margin-top: 30px;
    }
  }
}

.content__wrapper-content {
  ul {
    list-style-type: disc;
    margin-left: 23px;

    li {
      margin: 0;
    }
  }

  @include vp-768 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }

  @include vp-768 {
    img {
      height: 100%;
      max-height: unset;
    }

    div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: 54px;
      margin-right: 54px;
    }
  }
}

.content__wrapper-link {
  h3 {
    font-size: 24px;
    font-weight: 500;
    line-height: 29px;
    text-align: center;
    text-transform: uppercase;

    @include vp-768 {
      font-size: 32px;
      font-weight: 500;
      line-height: 38px;
    }
  }

  img {
    height: 180px;
    width: 100%;
    object-fit: cover;
    object-position: 50% 30%;
    margin: 22px auto;

    @include vp-768 {
      height: 500px;
      margin-top: 50px;
    }

    @include vp-1440 {
      height: 775px;
    }
  }

  .transparent-button {
    width: 372px;
    margin-top: 46px;
    margin-bottom: 50px;

    display: flex;
    align-items: center;
    justify-content: center;

font-size: 20px;
line-height: 28px;
  }
}
