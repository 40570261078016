*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
}

html {
  font-style: normal;
  font-weight: 500;
  font-size: $fz-default;
  line-height: 24px;
  font-family: $ff-default;
  color: $color-black;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

body {
  width: 100%;
  height: 100%;

  background-color: $color-default-white;
}

a {
  color: $color-default-black;
  text-decoration: none;
}

img,
video {
  display: block;
  max-width: 100%;
  height: auto;
}

textarea {
  resize: none;
}

input:-webkit-autofill {
  box-shadow: inset 0 0 0 1000px $color-default-white;

  -webkit-text-fill-color: $color-default-black;
}

// firefox placeholder \ invalid fix + ios bdrs
input,
textarea {
  border-radius: 0;

  &::placeholder {
    opacity: 1;
  }

  &:invalid {
    box-shadow: none;
  }
}

select {
  border-radius: 0;
}

// ie11 X removal
input {

  &::-ms-clear,
  &::-ms-reveal {
    display: none;
  }
}

// chrome search X removal
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  appearance: none;
}

// input[number] arrows removal
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  margin: 0;

  appearance: none;
}

input[type="number"] {
  appearance: textfield;
}

// ios button \ inputs reset
select,
textarea,
input:matches([type="email"],
  [type="number"],
  [type="password"],
  [type="search"],
  [type="tel"],
  [type="text"],
  [type="url"]) {
  appearance: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  appearance: none;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

button {
  cursor: pointer;
}

.title-lowcase {
  font-size: 32px;
  font-weight: 500;
  line-height: 38px;
  text-align: center;
  margin-top: 22px;
  margin-bottom: 22px;

  @include vp-1440 {
    font-size: 48px;
    font-weight: 500;
    line-height: 58px;
    text-align: center;
    margin-top: 48px;
    margin-bottom: 48px;
  }
}

.transparent-button {
  background-color: transparent;
  color: $color-blue;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  width: 100%;
  padding: 11px 10px;
  border: 1px solid $color-blue;
  border-radius: 4px;
  display: inline-block;
  margin: 23px auto 40px;

  @include vp-768 {
    width: max-content;
    margin-left: 0;
  }

  &:hover,
  &:focus,
  &:active {
    background-color: $color-blue;
    color: $color-default-white;
  }
}
