.footer {
  background-color: $color-light;
}

.footer .container {
  padding: 17px 16px;

  @include vp-1440 {
    padding: 22px 32px;
  }
}

.footer__list {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 15px 6px 15px;
  gap: 16px;

  @include vp-1440 {
    gap: 24px;
    justify-content: center;
    flex-direction: row;
    padding-top: 10px;
    padding-bottom: 2px;
  }

  li a {
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    color: $color-black;
    display: block;
  }

  a {

    &:hover,
    &:focus,
    &:active {
      text-decoration: underline;
      text-underline-offset: 4px;
    }
  }
}

.footer__copyright {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  color: $color-gray;
  margin: 0;

  padding: 10px 20px 10px;
}
