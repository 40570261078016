.hero {
  width: 100%;
  height: 100%;
  position: relative;

  z-index: 0;

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #0000001A;
  }

  img {
    object-fit: cover;
    object-position: center 60%;
    width: 100%;
    height: 397px;

    @include vp-1440 {
      height: 584px;
    }
  }
}

.hero .container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.hero__wrapper,
.hero__text-wrapper {
  position: absolute;
  width: 100%;
  height: auto;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 1;

  @include vp-1440 {
    top: 25px;
  }

  h2 {
    font-size: 32px;
    font-weight: 500;
    line-height: 38px;
    text-align: center;
    color: $color-default-white;
    text-transform: uppercase;
    max-width: 820px;
    margin-left: auto;
    margin-right: auto;

    @include vp-1440 {
      font-size: 48px;
      line-height: 58px;
    }
  }

  p {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    color: $color-default-white;
    text-transform: uppercase;
    margin-top: 24px;
    margin-bottom: 0;

    @include vp-1440 {
      font-size: 20px;
      line-height: 28px;
    }
  }

  a {
    display: block;
    width: 224px;
    background-color: $color-default-white;
    padding: 12px 32px;
    text-align: center;
    border-radius: 4px;
    margin: 0 auto;

    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }
}

.hero__text-wrapper {
  @include vp-1440 {
    top: 40px;
  }

  h2 {
    margin-top: 8px;
    margin-bottom: 18px;
    padding: 0 10px;
  }
}
