.message {
  width: 100%;
  background-color: $color-black;
  color: $color-default-white;

  opacity: 1;
  -moz-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.message__wrapper {
  position: relative;
  padding: 12px 24px 10px;

  p {
    margin: 0;

    text-align: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
  }

  @include vp-1440 {
    padding: 16px 24px 13px;
  }
}

[data-button="close-message"] {
  background-color: $color-black;
  border: none;
  width: 24px;
  height: 24px;
  position: absolute;
  right: 0;
  top: 11px;

  svg {
    position: absolute;
    top: 0;
    left: 0;
    fill: $color-default-white;
  }

  &:hover,
  &:focus,
  &:active {
    svg {
      fill: $color-gray;
    }
  }
}
