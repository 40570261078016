.container {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  padding: 0 16px;
  position: relative;

  // @include vp-768 {
  //   padding: 0 32px;
  // }

  @include vp-1440 {
    padding: 0 32px;
  }

  @include vp-1920 {
    padding: 0 100px;
  }
}
