.contacts {
  margin-top: 23px;
  margin-bottom: 50px;

  @include vp-768 {
    margin-top: 48px;
    margin-bottom: 68px;
  }
}

.contacts__list {
  display: flex;
  flex-direction: column;
  gap: 18px;

  @include vp-768 {
    gap: 26px;
    justify-content: center;
    flex-direction: row;
    flex-grow: 1;
    flex-basis: 0;
    flex-wrap: wrap;
  }

  .content__extra-text {
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 24px !important;

  }

  li {
    text-align: center;
    width: 100%;

    @include vp-768 {
      width: 40%;
    }

    @include vp-1440 {
      width: 32%;
    }

    h3 {
      font-size: 20px;
      font-weight: 500;
      line-height: 28px;
      text-align: center;
      margin-top: 13px;
      margin-bottom: 9px;

      @include vp-768 {
        margin-top: 10px;
      }
    }

    .contacts__links {
      a {
        &:hover,
        &:focus,
        &:active {
          text-decoration: underline;
          text-underline-offset: 4px;
        }
      }

      div {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 13px;
      }

      div a {
        color: $color-blue;
        font-family: $ff-default;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        display: inline-block;
        margin: 0 7px;

        svg {
          fill: $color-blue;
          margin-left: 3px;
          margin-top: 6px;
          display: block;
        }
      }
    }

    .contacts__links-list {
      div {
        margin-bottom: 16px;
      }

      div a {
        color: $color-blue;
        font-family: $ff-default;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        display: inline-block;
        margin: 0 7px;
      }
    }
  }
}

.content__text {
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  margin-top: 3px;
  margin-bottom: 3px;
}

.content__extra-text {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: $color-gray;
  margin-top: 3px;
  margin-bottom: 13px;
}
