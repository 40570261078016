.accordion {
  margin: 40px auto 0px auto;
  max-width: 1240px;

  & & {
    width: 90%;
    margin: 0 auto;
  }

  &__element:last-child {
    margin-bottom: 0;
  }

  &__element {
    margin-bottom: 0;
    border-bottom: 1px solid $color-light-gray;

  }

  &__element.is-active>&__button {
    opacity: 0.5;
  }

  &__element svg {
    transition-duration: 0.2s;
    align-self: flex-start;
    margin-top: 25px;
  }

  &__element.is-active svg {
    transform: rotate(-180deg);
    transition-duration: 0.2s;
  }

  &__button {
    width: 100%;
    // padding: 10px;
    padding: 0;
    border: none;
    background-color: transparent;

    text-transform: uppercase;

    cursor: pointer;

    // font-family: $ff-syncopate;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    // color: $color-cello;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;

    svg {
      margin-left: 10px;
    }

    p {
      margin: 5px 0;
    }
  }

  &__wrapper {
    padding: 10px;
  }

  &__text-content {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    margin: 5px 0;
  }

  .accordion__button {

    color: $color-gray;

    svg {
      stroke: $color-gray;
      fill: transparent;
      transition-duration: 0.2s;
    }

    &:hover,
    &:focus,
    &:active {
      color: $color-blue;

      svg {
        stroke: $color-blue;
        fill: transparent;
        transition-duration: 0.2s;
      }
    }

    .svg {
      width: 100%;
      height: 20px;
    }

  }
}


.accordion__text-wrapper {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-transform: none;
  color: $color-black;
  max-width: 90%;

  p {
    margin: 23px 0;
  }
}

.accordion__button {

  &:hover,
  &:focus,
  &:active {
    p {
      color: $color-blue;
    }
  }
}


.accordion__text-content {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: $color-gray;
  max-width: 90%;
}

.accordion__wrapper {
  padding: 0;
  padding-bottom: 20px;
}

.accordion__element .accordion {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-left: 0;
  margin-right: 0;

  .accordion__element {
    border-bottom: none;

    .accordion__button {

      &:hover,
      &:focus,
      &:active {
        text-decoration: none !important;
      }
    }

    .accordion__container {
      svg {
        width: 12px;
        margin-top: 17px;
      }
    }

    .accordion__text-wrapper {
      p {
        margin: 2px 15px;
        font-size: 15px;
        font-weight: 400;
        line-height: 22px;
      }

      span {
        font-size: 12px;
        font-weight: 400;
        line-height: 12px;
        color: $color-gray;
        margin-left: 15px;
      }

    }
  }

  .accordion__element.is-active svg {
    transform: rotate(-180deg) !important;
  }

  .accordion__list {
    border-radius: 4px;
    background-color: $color-light-gray-2;
    margin-left: 36px;
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 4px 2px;

    li {
      div {
        display: flex;
        justify-content: space-between;
        padding: 4px 8px;
      }

      p {
        font-size: 12px;
        font-weight: 400;
        line-height: 12px;
        color: $color-gray;
        margin: 0;
      }
    }
  }
}
