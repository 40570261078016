.content h3.assistance__pre-title {
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  text-align: left;
  margin-top: 24px;
  margin-bottom: 20px;

  @include vp-768 {
    margin-bottom: 10px;
  }
}

.assistance__text-block {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  color: $color-gray;
  margin-top: 20px;
  margin-bottom: 20px;

  @include vp-768 {
    max-width: 92%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 25px;
    margin-bottom: 40px;
  }
}

.questions__contacts {
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  gap: 26px;

  @include vp-768 {
    flex-direction: row;
    justify-content: center;
    gap: 50px;
    padding: 40px 0;
  }
}

.questions__text {
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  text-align: center;
  margin-bottom: 3px;

  @include vp-768 {
    text-align: left;
  }
}

.questions__extra-text {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: $color-gray;

  @include vp-768 {
    text-align: left;
  }
}

.fast-links {
  margin: 54px 0;

  @include vp-768 {
    margin: 90px 0;
  }

  li {
    border-bottom: 1px solid $color-light-gray;
    padding: 15px 0 16px;

    @include vp-768 {
      padding: 24px 0;
    }

    display: flex;
    justify-content: space-between;
  }

  .assistance__text-block {
    text-align: left;
    margin-top: 66px;
    margin-bottom: 64px;

    @include vp-768 {
      text-align: center;
      margin-top: 95px;
      margin-bottom: 95px;
    }
  }

  ul {
    @include vp-768 {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      grid-auto-flow: column;
      gap: 0 36px;
    }
  }

  a {
    &:hover,
    &:focus,
    &:active {
      text-decoration: underline;
      text-underline-offset: 4px;
    }
  }
}

.fast-links p {
  text-align: center;
  position: relative;
  margin-bottom: 26px;

  @include vp-768 {
    font-size: 32px;
    font-weight: 500;
    line-height: 38px;
  }
}

.fast-links li svg {
  stroke: $color-gray;
  margin-top: 4px;
}

.fast-links li:first-child {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0%;
    height: 1px;
    background-color: $color-light-gray;
  }
}

@include vp-768 {
  .fast-links li:nth-child(2n-1) {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 0%;
      height: 1px;
      background-color: $color-light-gray;
    }
  }
}

.fast-links li a {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: $color-blue;
}

.questions {
  @include vp-768 {
    margin-top: 90px;
  }

  .content__pre-title {
    @include vp-768 {
      margin-bottom: 20px;
    }
  }

  .title-lowcase {
    @include vp-768 {
      margin-top: 20px;
    }

    &--extra {
      font-size: 20px;
      font-weight: 500;
      line-height: 28px;
      text-align: center;
      margin-bottom: 25px;

      @include vp-768 {
        font-size: 32px;
        line-height: 38px;
      }
    }
  }
}

.notes__text-block {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: $color-gray;
  margin-top: 70px;
  margin-bottom: 20px;

  @include vp-768 {
    margin-left: auto;
    margin-right: auto;
    margin-top: 45px;
    margin-bottom: 45px;
  }
}
