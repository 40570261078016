.form__checkbox {
  display: inline-block;
  position: relative;
  padding-left: 52px;
  cursor: pointer;
}

.form__checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  right: 0;
  top: 0;
}

.form__checkbox input:checked:not(:disabled)~.form__checkmark {
  background-color: $color-blue;
  border-color: $color-blue;
}

.form__checkbox input:checked~.form__checkmark::after {
  left: 16px;
}

.form__checkbox:hover input~.form__checkmark {
  border-color: $color-dark-gray;
  background-color: $color-light-gray-2;
}

.form__checkbox:hover input:not(:disabled):checked~.form__checkmark {
  background-color: $color-blue;
}

.form__checkbox .form__checkmark {
  position: absolute;
  right: 0;
  top: 14px;
  width: 40px;
  height: 24px;
  border-color: $color-dark-gray;
  background-color: $color-light-gray-2;
  border: 2px solid #bdbdbd;
  border-radius: 12px;

  // @include vp-768 {
  //   top: 0;
  // }
}

.form__checkbox input:disabled~.form__checkmark {
  border-color: #e2e2e2;
  background-color: #e2e2e2;
}

.form__checkbox .form__checkmark::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  display: block;
  background-color: $color-default-white;
  border-radius: 50%;
  transition: left 0.25s ease;
  box-shadow: 0px 2px 5px 0px #00000066;
}

.form__checkbox input:disabled:not(:checked)~.form__checkmark::after {
  background-color: #f8f8f8;
}

.form__checkbox input:disabled:checked~.form__checkmark::after {
  background-color: #bdbdbd;
}

.form__checkbox .form__body {
  color: #333333;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  text-transform: none;
}

input:disabled~.form__body {
  color: #bdbdbd;
}

.cookie {
  .logo {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    margin-bottom: 10px;

    background-color: $color-light;
  }
}

.cookie-set {
  .logo {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    margin-bottom: 55px;

    background-color: $color-light;
  }

  .cookies-form-buttons {
    background-color: $color-light;
    padding: 20px;
    margin-top: 40px;

    button {
      border: none;
      background-color: transparent;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;

      &:hover,
    &:focus,
    &:active {
      text-decoration: underline;
      text-underline-offset: 4px;
    }
    }

    .container form {
      justify-content: center;
      align-items: center;
      display: flex;
      flex-direction: column;
      gap: 10px;

      @include vp-768 {
        flex-direction: row;
        gap: 30px;
      }
    }
  }

  .accordion__container {
    display: flex;
  }

  .accordion__button {
    justify-content: flex-start;
    align-items: center;

    svg {
      margin-left: 0;
      margin-top: 22px;
    }
  }

  .accordion__text-wrapper {
    p {
      margin: 15px;
    }
  }
}

.cookies__wrapper {

  .text {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: $color-gray;

    a,
    .accordion__wrapper button {
      text-decoration: underline;
      text-underline-offset: 4px;
    }
  }

  .button__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;

    button {
      width: 100%;
      display: block;
      padding: 8px;
      background-color: $color-default-white;
      border: none;
      border: 1px solid $color-black;
      border-radius: 4px;

      &:hover,
      &:focus,
      &:active {
        background-color: $color-black;
        border-color: $color-black;
        color: $color-default-white;
        transition: all 0.3s;
      }
    }
  }
}

.cookie__block {
  @include vp-1440 {
    margin-bottom: 33px;
  }

  .title {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    margin-top: 25px;
    margin-bottom: 18px;
  }

  .text {
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    color: $color-gray;
  }

  a,
  .accordion__wrapper button {
    color: $color-blue;
    border: none;
    background-color: transparent;

    &:hover,
    &:focus,
    &:active {
      text-decoration: underline;
      text-underline-offset: 4px;
    }
  }

  svg {
    fill: $color-blue;
  }

  .accordion__content {
    svg {
      transform: rotate(0) !important;
    }
  }
}

.cookie {
  width: 100%;
  max-width: 496px;
  height: 100%;
  margin: 0 auto;
  padding: 4px 0 25px;
  background-color: $color-default-white;
}

.cookie-set {
  width: 95%;
  max-width: 800px;
  margin: 0 auto;
  // padding: 4px 0 25px;
  background-color: $color-default-white;
  overflow-y: auto;
  position: fixed;
  max-height: 100vh;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .accordion__text-content,
  .accordion__wrapper button {
    margin-left: 36px;
    margin-right: 20px;
    padding: 0;
  }

  .cookie__block .text {
    margin-top: 18px;
  }

  .cookie__block .title {
    margin-bottom: 14px;
  }

  .accordion {
    margin-top: 10px;
  }
}


.modal-cookie {
  position: fixed;
  max-height: 100vh;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
